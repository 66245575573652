.formContentBox[data-v-e3a9f614] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-e3a9f614] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.formTopic[data-v-e3a9f614] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-e3a9f614] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 60px;
}
.shuttleBackBox .el-tabs[data-v-e3a9f614] {
  width: 100%;
}
.shuttleBackBox .upload-demo[data-v-e3a9f614] {
  background-color: #ffffff;
  padding-left: 10px;
}
.shuttleBackBox .table-box[data-v-e3a9f614] {
  width: calc(100% - 280px);
}
.shutleBack[data-v-e3a9f614] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-e3a9f614] {
  margin: 211px 12px;
}
.shutleTitle[data-v-e3a9f614] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-e3a9f614] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.deptBox[data-v-e3a9f614] {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.unitBox[data-v-e3a9f614] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.unitTitle[data-v-e3a9f614] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}